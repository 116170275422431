<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span class="text-16 text-white pt-3 pb-3"
                      @click.prevent="$router.push({name: 'Home'})">{{ $t('manage-courses.homepage') }}</span>
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"><img src="assets/img/icons/bc-seperator.svg" class="h-100"
                                                      alt=""></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{ $t('manage-courses.managing-course') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <section id="course-management">
              <!-- Main buttons -->
              <div class="row" v-if="$store.state.user?.role === 'Admin'">
                <div class="col-12">
                  <button class="btn btn-theme ms-auto float-right mb-3" data-toggle="modal"
                          data-target="#recommendedCoursesModal">{{ $t('manage-courses.manage') }}
                  </button>
                </div>
                <RecommendedCoursesModal/>
              </div>
              <!-- New course and list-->
              <div class="row">
                <!-- New course button -->
                <div class="col-lg-3">
                  <div class="flex-wrap d-flex">
                    <div class="d-flex align-items-center pointer mr-auto"
                         @click.prevent="$router.push({name: 'CreateCourse'})">
                      <div class="new-button-50 d-flex align-items-center justify-content-center">
                        <img src="assets/img/icons/plus.svg" class="img-fluid" alt="">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <h6 class="text-16 text-dark-gray">{{ $t('manage-courses.create-new') }}</h6>
                      </div>
                    </div>
                    <div class="col mt-4" v-if="$store.state.user?.role === 'Admin'">
                      <label class="text-16 text-dark-gray mb-0">{{ $t('chat.search') }}:</label>
                      <input type="text" v-debounce:300="search" :disabled="loading" class="form-control"/>
                      <div class="form-check mt-3">
                        <input class="form-check-input" @change="filterCourses($event)" type="radio" :value="null" name="group" checked id="all">
                        <label class="form-check-label" for="flexRadioDefault1">
                          {{ $t('manage-courses.all') }}
                        </label>
                      </div>
                      <div v-for="(group) in groups" :key="group.id">
                        <div v-show="(groups.some(c=>(c.id==selectedGroup?.id)) || groups.some(c=>(c.children.length))) || !selectedGroup" class="form-check">
                          <input class="form-check-input" @change="filterCourses($event)" type="radio" name="group" :value="group.id" id="flexRadioDefault1">
                          <label class="form-check-label" for="flexRadioDefault1">
                            {{group.title}}
                          </label>
                          <div v-for="(subGroup) in group.children" :key="subGroup">
                            <div v-show="(groups.some(c=>(c.id==subGroup?.id)) || groups.some(c=>(c.children.length)))" class="ml-3 form-check">
                              <input class="form-check-input" @change="filterCourses($event)" type="radio" name="group" :value="subGroup.id" id="flexRadioDefault1">
                              <label class="form-check-label" for="flexRadioDefault1">
                                {{subGroup.title}}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Course list -->
                <div class="col-lg-9">
                  <div class="row" v-if="!loading">
                    <!-- COURSE ITEM -->
                    <div v-for="(course, index) in courses" :key="course.id" class="col-12 mb-3">
                      <div v-if="index>0 && course.course_group_id!=courses[index-1]?.course_group_id"
                           class="separator">{{ course.group?.title }}
                      </div>

                      <div class="item-box bg-light-gray-primary filter-shadow">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="course-image"
                                 :style="{backgroundImage: `url(${course.cover_image ? $store.getters.getStorageURL(course.cover_image.path) : 'assets/img/demo/training.png'})`}"></div>
                          </div>
                          <div class="col-lg-5 pt-4 pb-4">
                            <h2 class="text-white text-18 semi-bold">{{ course.title }}</h2>
                            <h2 class="text-white text-14 regular mb-3">{{ course.course_type.name }}</h2>
                            <div class="flex-wrap d-flex">
                              <div class="d-flex">
                                <h3 class="text-11 regular text-white">{{ $t('manage-courses.cbegin') }}:</h3>
                              </div>
                              <div class="d-flex ms-auto">
                                <h3 class="text-12 medium text-white">
                                  {{
                                    course.start_date ? $store.getters.parseDateTime(course.start_date) : $t('manage-courses.no')
                                  }}</h3>
                              </div>
                            </div>
                            <div class="flex-wrap d-flex mb-3">
                              <div class="d-flex">
                                <h3 class="text-11 regular text-white">{{ $t('manage-courses.cend') }}:</h3>
                              </div>
                              <div class="d-flex ms-auto">
                                <h3 class="text-12 medium text-white">
                                  {{
                                    course.end_date ? $store.getters.parseDateTime(course.end_date) : $t('manage-courses.no')
                                  }}</h3>
                              </div>
                            </div>
                            <h2 v-if="course.active " class="text-13 mb-1 text-white medium">
                              <img src="assets/img/icons/eye.svg" alt="">
                              {{ $t('manage-courses.public') }}
                            </h2>
                            <h2 v-else class="text-13 mb-1 text-white medium">
                              <i class="fas fa-eye-slash"></i>
                              {{ $t('manage-courses.hidden') }}
                            </h2>
                            <h2 v-if="course.finalized" class="text-13 text-white medium">
                              {{ $t('manage-courses.finalized') }}
                            </h2>
                          </div>
                          <div class="container col-lg-3 pt-4 pb-4">
                            <div class="row row align-items-start" v-if="course.creator_id== $store.state.user.id  || $store.state.user.role=='Admin'">
                              <img @click.prevent="$router.push({name: 'EditCourse', params: {course: course.id}})"
                                   src="assets/img/icons/pen.svg" class="float-right pointer remove pr-2 pt-4" alt="">
                            </div>
                            <div class="row align-items-end"
                                 style="margin-top: 56% !important; margin-left: 36% !important;">
                              <div class="btn-group btn-group-sm" role="group" aria-label="...">
                                <button type="button" @click.prevent="updateCourseOrder(course, index, 'down')"
                                        class="btn btn-light"><i class="fa fa-arrow-down" aria-hidden="true"></i>
                                </button>
                                <button type="button" disabled class="btn btn-light">{{ course.order }}</button>
                                <button type="button" @click.prevent="updateCourseOrder(course, index, 'up')"
                                        class="btn btn-light"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 v-if="courses.length == 0" class="text-13 text-dark-gray semi-bold">
                        {{
                          $store.state.user?.role === 'Admin' ? $t('course-catalog.empty-course') : 'Még nem hoztál létre képzést'
                        }}</h6>
                    </div>
                    <!-- //COURSE ITEM -->
                  </div>
                  <loading v-else/>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Loading from '@/components/Loading'
import RecommendedCoursesModal from '../components/RecommendedCoursesModal.vue'

export default {
  name: 'CourseManagement',
  components: {
    Loading,
    RecommendedCoursesModal
  },
  mounted() {
    this.getCourseGroups();
    this.getCourses();
  },
  methods: {
    search(string) {
      if (string !== this.searchString) {
        this.searchString = string;
        this.getCourses()
      }
    },
    //filter courses by group
    filterCourses(event) {
      this.courseGroupId = event.target.value;
      if (this.courseGroupId){
        this.selectedGroup = this.groups.find(group => group.id == this.courseGroupId);
      }
      this.getCourses();
    },
    getCourses() {
      //this.disabled = true;
      this.axios.get('/courses/admin', {params: {search: this.searchString, group: this.courseGroupId}})
          .then(resp => this.courses = resp.data.courses)
          .finally(() => this.loading = false);
    },
    getCourseGroups() {
      this.loading = true;
      this.axios.get('/course-groups')
          .then(resp => this.groups = resp.data.course_groups)
          .finally(() => this.loading = false);
    },
    updateCourseOrder(course, order, direction) {
      this.loading = true;
      this.axios.post('/courses/' + course.id + '/order', {direction: direction})
          .finally(this.getCourses);
      ///courses/{course}/order
    }
  },
  data() {
    return {
      courses: [],
      searchString: '',
      courseGroupId: null,
      selectedGroup: null,
      groups: null,
      loading: false
    }
  }

}
</script>
<style scoped>
#course-management {
  margin-top: 30px;
}

.item-box {
  border-radius: 8px;
  padding: 0px;
}

.course-image {
  height: 100%;
  border-radius: 8px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>
