<template>
  <div>
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="$router.push({ name: 'Home' })"
                  >{{ $t("my-courses.homepage") }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="$router.push({ name: 'CourseCatalog' })"
                  >{{ $t("course.courses") }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="
                    $router.push({ name: 'Course', params: { id: course.id } })
                  "
                  >{{ loading || !course ? "Képzés" : course.title }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{
                  $t("view-course.details")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <section id="subpage">
        <div class="row">
          <!-- Course details -->
          <div class="col-xl-4 col-12">
            <div class="sub-left">
              <div class="sub-item-box">
                <h2 class="mb-1">{{ course.title }}</h2>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: Math.floor(overallProgress) + '%' }"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <h3 class="hidden-mobile">{{ overallProgress }}%</h3>
              </div>

              <div class="sub-item-box start-box mt-4">
                <button
                  class="btn btn-primary start-btn w-100"
                  @click.prevent="selectNextMaterial"
                >
                  {{ $t("view-course.click-to-start") }}
                </button>
              </div>

              <div class="accordion" id="accordionExample">
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed panel-title"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        <div>{{ $t("view-course.course-material") }}</div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse show"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <h5
                        class="data-filter"
                        v-for="(material, idx) in course.course_materials"
                      >
                        <div
                          class="d-flex align-items-center"
                          style="padding: 5px"
                          v-if="!material.locked"
                          @click.prevent="selectMaterial(material.id)"
                          :class="
                            selected == material.id ? 'selected-material' : ''
                          "
                        >
                          <div class="d-inline-flex">
                            <i :class="getIcon(material)"></i>
                          </div>
                          <div
                            class="ml-2"
                            style="word-break: break-word"
                            @click.prevent="scrollIntoFrame"
                          >
                            {{ getName(material) }}
                          </div>
                        </div>
                        <div class="d-flex align-items-center locked" v-else>
                          <div class="d-inline-flex">
                            <i class="fas fa-lock"></i>
                          </div>
                          <div class="ml-2" style="word-break: break-word">
                            {{ getName(material) }}
                          </div>
                        </div>
                        <hr />
                      </h5>
                      <button
                        v-if="overallProgress === 100"
                        class="btn btn-primary w-100"
                        data-toggle="modal"
                        data-target="#congratulationsModal"
                      >
                        <i class="fas fa-arrow-left"></i>
                        <span class="ml-1">{{ $t("course.finish") }}</span>
                      </button>
                      <button
                        v-else-if="overallProgress !== 100"
                        class="btn btn-primary w-100"
                        @click.prevent="
                          $router.push({
                            name: 'Course',
                            params: { id: course.id },
                          })
                        "
                      >
                        <i class="fas fa-arrow-left"></i>
                        <span class="ml-1">{{ $t("course.finish") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left panel-title"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {{ $t("view-course.about") }}
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <h5 class="acc-lore">
                        <strong>{{ $t("view-course.cname") }}:</strong>
                        <span>{{ course.title }}</span>
                      </h5>
                      <h5 class="acc-lore">
                        <strong>{{ $t("view-course.ctype") }}:</strong>
                        <span>{{
                          $t(`course-types.${course.course_type.name}`)
                        }}</span>
                      </h5>
                      <h5 class="acc-lore">
                        <strong>{{ $t("view-course.cbegin") }}:</strong>
                        <span>{{
                          course.start_date
                            ? $store.getters.parseDateTime(course.start_date)
                            : $t("common.no-set")
                        }}</span>
                      </h5>
                      <h5 class="acc-lore">
                        <strong>{{ $t("view-course.cend") }}:</strong>
                        <span>{{
                          course.end_date
                            ? $store.getters.parseDateTime(course.end_date)
                            : $t("common.no-set")
                        }}</span>
                      </h5>
                      <h5 class="acc-lore">
                        <strong>{{ $t("view-course.duration") }}:</strong>
                        <span>{{ courseDuration(course.duration) }}</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed panel-title"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {{ $t("edit-course.course-desc") }}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <h5>{{ course.contents }}</h5>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFour">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed panel-title"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        {{ $t("view-course.about-presenters") }}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <h5>{{ $t("view-course.presenters") }}</h5>
                      <ul>
                        <li
                          v-if="course.external_presenters"
                          v-for="ext in String(
                            course.external_presenters
                          ).split(',')"
                        >
                          {{ ext }}
                        </li>
                        <li v-for="presenter in course.presenters">
                          {{ presenter.name }}
                        </li>
                        <li
                          v-if="
                            !course.external_presenters &&
                            course.presenters.length == 0
                          "
                        >
                          {{ $t("create-course.no-speakers") }}
                        </li>
                      </ul>
                      <h5>{{ course.description }}</h5>
                    </div>
                  </div>
                </div>
                <div
                  class="card"
                  v-if="
                    $store.state.user &&
                    course.creator_id != $store.state.user.id
                  "
                  :creatorId="course.creator_id"
                >
                  <div class="card-header" id="headingSix">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed panel-title"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        {{ $t("expert.title") }}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseSix"
                    class="collapse"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <ask-the-expert
                        :creatorId="course.creator_id"
                        :presenters="course.presenters"
                      />
                    </div>
                  </div>
                </div>
                <div class="card" v-if="course.hasFeedback">
                  <div class="card-header" id="headingFive">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed panel-title"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        {{ $t("view-course.rate") }}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    class="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      <div
                        class="d-flex align-items-center mb-4"
                        v-if="!course.userCanViewFeedback"
                      >
                        <div
                          class="mr-1"
                          style="margin-left: 3px; height: 20px"
                        >
                          <i
                            class="fas fa-info-circle font-20"
                            style="
                              color: var(--black);
                              font-size: 20px !important;
                            "
                          ></i>
                        </div>
                        <div>{{ $t("view-course.ratification") }}</div>
                      </div>

                      <div class="d-flex align-items-center">
                        <h2
                          class="font-20"
                          style="
                            font-size: 20px !important;
                            display: flex;
                            align-items: center;
                            color: rgb(33, 37, 41) !important;
                          "
                          v-if="course.userCanViewFeedback"
                        >
                          <i
                            class="fas fa-star mr-2 font-25"
                            style="
                              font-size: 25px !important;
                              width: 25px !important;
                            "
                          ></i>
                          <a
                            v-if="course.userCompletedFeedback"
                            style="text-decoration: none !important"
                          >
                            <span style="color: rgb(33, 37, 41) !important"
                              >{{ $t("view-course.course-rate") }} - </span
                            ><span class="text-success">
                              {{ $t("view-course.filled") }}</span
                            >
                          </a>
                          <a
                            v-else
                            href="#"
                            style="text-decoration: none !important"
                            @click.prevent="selectFeedback"
                          >
                            <span style="color: rgb(33, 37, 41) !important"
                              >{{ $t("view-course.course-rate") }} - </span
                            ><span class="text-danger">
                              {{ $t("view-course.not-filled") }}</span
                            >
                          </a>
                        </h2>
                        <h2
                          class="font-20"
                          style="
                            font-size: 20px !important;
                            display: flex;
                            align-items: center;
                            color: #747474 !important;
                          "
                          v-else
                        >
                          <i
                            class="fas fa-lock mr-2 font-25"
                            style="
                              font-size: 25px !important;
                              width: 25px !important;
                            "
                          ></i>
                          <a style="text-decoration: none !important">
                            <span>{{ $t("view-course.course-rate") }}</span>
                          </a>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  COURSE CONTENT -->
          <div class="col-xl-8 col-12 right-side" id="material-frame">
            <div class="sub-right">
              <CourseMaterialList
                v-if="!feedbackSelected"
                :selected="selected"
                :materials="course.course_materials"
                @changeSelected="selectMaterial"
                @pullback="load"
                @loadCourse="load"
                :course="course"
                :overallProgress="overallProgress"
              />
              <div
                v-else
                class="p-4"
                style="background: white; border-radius: 5px"
              >
                <CreateUserCourseFeedback
                  @submitted="onSubmitted"
                  :course="course.id"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="col-12">
      <Loading height="80vh" />
    </div>
  </div>
  <!-- Congratulations modal -->
  <div
    v-if="course != null"
    class="modal fade"
    id="congratulationsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="congratulationsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="congratulationsModalLabel">
            {{ $t("view-course.congratulations") }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 p-1">
              <div class="mx-auto mt-4" style="height: 31px; width: 44px">
                <svg
                  width="44"
                  height="31"
                  viewBox="0 0 44 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 15.3176L15.3176 28.6353L42 2"
                    stroke="#00BD2A"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p class="col-12 text-center">
              {{ $t("view-course.congratulations-text") }}
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click.prevent="
              $router.push({ name: 'Course', params: { id: course.id } })
            "
          >
            <i class="fas fa-arrow-left"></i>
            <span class="ml-1">{{ $t("course.course-page") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
import CourseMaterialList from "@/components/CourseMaterialList.vue";
import AskTheExpert from "@/components/AskTheExpert.vue";
import CreateUserCourseFeedback from "./CreateUserCourseFeedback";
import course from "@/views/Course";
export default {
  name: "ViewCourse",
  components: {
    Loading,
    CourseMaterialList,
    AskTheExpert,
    CreateUserCourseFeedback,
  },
  mounted() {
    document.addEventListener("contextmenu", this.prevent, false);

    this.load(true);
  },
  beforeUnmount() {
    document.removeEventListener("contextmenu", this.prevent);
  },
  data() {
    return {
      course: null,
      loading: true,
      selected: null,
      feedbackSelected: false,
    };
  },
  computed: {
    overallProgress() {
      let progress = 0;
      if (this.course.test_attempts) {
        this.course.test_attempts.forEach((ta) => {
          if (ta.percentage >= ta.test.min_pass_percentage) {
            progress = 100;
          }
        });
      }
      if (progress == 100) {
        this.axios
          .post(`/courses/${this.course.id}/finish-course`)
          .then((resp) => {
            console.log(resp);
          })
          .catch((error) => {
            console.log(error);
          });
        return 100;
      }
      this.course.course_materials.forEach((cm) => {
        progress +=
          cm.course_material_progress.length > 0
            ? cm.course_material_progress[0].progress
            : 0;
      });
      //console.log(this.course.duration, progress)
      if (this.course.duration == progress) return 100;
      return this.course.duration !== 0
        ? Math.floor(progress / (this.course.duration / 100))
        : 0;
    },
  },
  watch: {
    loading(newVal, prevVal) {
      if (this.selected && newVal === false && prevVal === true)
        this.$nextTick(() => this.scrollIntoFrame());
    },
  },
  methods: {
    prevent(e) {
      e.preventDefault();
    },
    load(firstLoad = false) {
      this.axios
        .get("/courses/" + this.$route.params.id)
        .then((resp) => {
          this.course = resp.data.course;
          const isOwned = resp.data.course.owned;
          if (!isOwned) {
            this.$router.replace({
              name: "Course",
              params: { id: this.$route.params.id },
            });
          } else if (!resp.data.course.user_contract && false) {
            this.$router.replace({
              name: "CreateFarContract",
              params: { course: this.$route.params.id },
            });
          } else if (this.course.course_materials)
            if (
              firstLoad &&
              this.$store.state.user.activeTestAttempt?.courses[0]?.id ==
                this.course.id
            ) {
              this.selected = this.course.course_materials.find(
                (m) =>
                  m.course_test?.test_id ==
                  this.$store.state.user.activeTestAttempt?.test_id
              )?.id;
            }
          console.log("course", this.course);
          this.course.course_materials.forEach((courseMaterial, index) => {
            if (index == 0 && firstLoad) this.selected = courseMaterial.id;
            if (index == 0 && courseMaterial.course_event && firstLoad)
              this.selected = courseMaterial.id;

            if (
              courseMaterial.course_event &&
              courseMaterial.course_event.zoom_meeting_id !== null
            ) {
              courseMaterial.course_event.loading = true;
              this.axios
                .get(
                  `/courses/${this.course.id}/events/${courseMaterial.course_event.id}`
                )
                .then(
                  (res) =>
                    (this.course.course_materials[index].course_event =
                      Object.assign(
                        this.course.course_materials[index].course_event,
                        res.data.courseEvent
                      ))
                )
                .catch((e) => console.log(e.response))
                .finally(() => (courseMaterial.course_event.loading = false));
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmitted() {
      this.course.userCompletedFeedback = true;
      this.feedbackSelected = false;
    },
    selectFeedback() {
      this.selected = null;
      this.feedbackSelected = true;
    },
    selectMaterial(selected) {
      this.selected = selected;
      this.feedbackSelected = false;
    },
    courseDuration(duration) {
      let all = duration;
      const hours = Math.floor(all / 3600);
      const minutes = Math.floor((all % 3600) / 60);
      const seconds = all - hours * 3600 - minutes * 60;
      return `${
        hours !== 0 ? hours + " " + this.$t("time-unit.hour") + " " : ""
      }${
        minutes !== 0 ? minutes + " " + this.$t("time-unit.minute") + " " : ""
      }${
        seconds !== 0 ? seconds + " " + this.$t("time-unit.second") + " " : ""
      }`;
    },
    getName(material) {
      //console.log(material)
      if (material.course_multimedia) {
        return material.course_multimedia.name;
      } else if (material.course_event) {
        return material.course_event.name;
      } else {
        return material.course_test.test.name;
      }
    },
    getIcon(material) {
      if (material.course_multimedia) {
        const type = material.course_multimedia.multimedia.type;
        if (type === "audio") {
          return "fas fa-microphone";
        } else if (type === "image") {
          return "fas fa-image";
        } else if (type === "document") {
          return "far fa-file-alt";
        } else {
          return "far fa-file-video";
        }
      } else if (material.course_event) {
        if (material.course_event.zoom_meeting_id) {
          return "fas fa-video";
        } else {
          return "fas fa-school";
        }
      } else {
        if (material.course_test.exam) {
          return "fas fa-clipboard-list text-orange";
        } else {
          return "fas fa-clipboard-list";
        }
      }
    },
    scrollIntoFrame() {
      if (window.innerWidth < 1200)
        document
          .getElementById("material-frame")
          .scrollIntoView({ behavior: "smooth" });
    },
    selectNextMaterial() {
      const selected =
        this.course.course_materials.find(
          (cm) => cm.course_material_progress < 100 && !cm.locked
        ) ?? this.course.course_materials[0];
      this.selectMaterial(selected.id);
      this.scrollIntoFrame();
    },
  },
};
</script>

<style scoped>
#subpage {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.selected-material {
  background-color: #176fb729;
  border-radius: 5px;
}
.locked {
  color: var(--cgray) !important;
  cursor: default;
}
#subpage {
  min-height: 100%;
}

@media (max-width: 991px) {
  .right-side {
    margin-top: 30px !important;
  }
}

.sub-left,
.sub-right {
  background-color: #e5e5e5;
  height: 100%;
  border-radius: 5px;
  padding: 25px;
}
.sub-item-box {
  background-color: var(--white);
  padding: 15px;
  border: none !important;
  border-radius: 5px;
}
.sub-item-box h2 {
  font-size: 16px;
  color: var(--black);
  margin-bottom: 10px;
}
.sub-item-box h3 {
  font-size: 16px;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 0px;
}
.sub-item-box .progress-bar {
  background-color: var(--red);
}
.panel-title::after {
  content: "\f107";
  color: var(--black);
  right: 15px;
  top: 10px;
  font-size: 25px;
  position: absolute;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
}

.panel-title[aria-expanded="true"]::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f106";
}

.panel-title {
  font-size: 18px;
}

.accordion {
  margin-bottom: 25px;
  margin-top: 25px;
}

.accordion .btn-link {
  color: var(--black) !important;
  text-decoration: none;
  padding: 0px !important;
}

.accordion .card-header {
  background-color: var(--white);
  border: none !important;
  padding: 15px !important;
}

.accordion .card-body {
  background-color: var(--white) !important;
  padding: 15px !important;
}

.accordion .card-body .data-filter {
  cursor: pointer;
}

.accordion .card-body * {
  font-size: 15px !important;
}

.sub-price {
  font-size: 16px !important;
  color: var(--black);
}
.sub-credit {
  font-size: 16px !important;
  color: var(--red);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px !important;
}

@media (min-width: 426px) {
  .start-box {
    display: none;
  }
}

#subpage {
  margin-top: 30px;
}
@media (max-width: 425px) {
  .panel-title {
    font-size: 12.5px !important;
  }
  .accordion .card-body * {
    font-size: 12px !important;
  }
  .sub-price,
  .sub-credit,
  .sub-item-box h2,
  .sub-item-box .start-btn,
  .sub-item-box h3 {
    font-size: 13px !important;
  }
  .panel-title::after {
    font-size: 17.5px !important;
  }
  .hidden-mobile {
    display: none !important;
  }
}
</style>
